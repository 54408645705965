
import { Component, Vue } from 'vue-property-decorator'
import { readIsDev, readShowMobileMenu } from '@/store/main/getters'
import I18nDropdown from '@/components/i18nDropdown.vue'
import { dispatchLogOut, dispatchToggleMobileMenu } from '@/store/main/actions'

@Component({
  components: { I18nDropdown },
})
export default class MobileMenu extends Vue {
  get showMobileMenu() {
    return readShowMobileMenu(this.$store)
  }

  public locales = [
    { code: 'ru', iso: 'ru-EU', file: 'ru.json', title: 'Русский' },
    { code: 'en', iso: 'en-US', file: 'en.json', title: 'English' },
    // { code: 'es', iso: 'es-EU', file: 'es.json', title: 'Español' },
    // { code: 'tr', iso: 'tr-EU', file: 'tr.json', title: 'Türkçe' },
    // { code: 'zh', iso: 'zh-CN', file: 'zh.json', title: '中文' },
    // { code: 'uk', iso: 'ua-EU', file: 'ua.json', title: 'Українська' },
    // { code: 'de', iso: 'de-EU', file: 'de.json', title: 'Deutsch' },
    // { code: 'pl', iso: 'pl-EU', file: 'pl.json', title: 'Polski' },
  ];

  public async switchLocalePath(locale) {
    this.$root.$i18n.locale = locale
    // await dispatchSetUserLanguage(this.$store, {language: locale});
    // window.location.reload();
    localStorage.setItem('locale', locale)
    this.hideMobileMenu()
  }

  public async handleLogOut() {
    await dispatchLogOut(this.$store);
  }

  public hideMobileMenu() {
    dispatchToggleMobileMenu(this.$store)
  }
}
