
import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Main/Header.vue'
import Footer from '@/components/Main/Footer.vue'
import MobileMenu from '@/components/Main/MobileMenu.vue'

import '@/assets/css/custom.css'
import '@/assets/css/style.min.css'
import '@/assets/css/data-aos.css'
import { api } from '@/api'
import { dispatchSetMe } from '@/store/main/actions'


@Component({
  components: {
    MobileMenu,
    Header,
    Footer,
  },
})
export default class MainMain extends Vue {

  public totalAttempts = 30
  public attempt = 0

  public async getAccountData() {
    console.debug('%c Main.vue getMe', 'color:lime;')

    try {
      const result = await api.getAccount(this.$store.state.main.token)
      // console.debug('%c me %s', 'color:aqua;', JSON.stringify(result.data, null, 4))

      if (result) {
        console.debug('dispatchSetMe(this.$store, result.data)')

        dispatchSetMe(this.$store, result.data)

        const token = result.data.jivo_jwt
        // console.debug('%c result.data.jivo_jwt: %s', 'color:orangered;', result.data.jivo_jwt)

        if (token) {
          // @ts-ignore
          window.jivo_jwt = token
          // @ts-ignore
          // console.debug('%c window.jivo_api:', 'color:lime;', window.jivo_api)
          // @ts-ignore
          // console.debug('%c window.jivo_jwt:', 'color:gold;', window.jivo_jwt)

          this.setUserToken()
        }
      }

    } catch (err) {
      console.debug('%c Main.vue getMe error', 'color:red;', err)
    }
  }

  public async setUserToken() {
    // console.debug('%c setUserToken(attempt: %s)', 'color:lime;', this.attempt)
    if (this.attempt > this.totalAttempts) return

    this.attempt++

    // @ts-ignore
    if (window.jivo_api) {
      // @ts-ignore
      console.debug('%c window.jivo_api:', 'color:aqua;', window.jivo_api)

      // @ts-ignore
      const clearHistoryResult = await window.jivo_api.clearHistory()
      console.debug('%c Main.vue clearHistoryResult:', 'color:lime;font-size:18px;', clearHistoryResult)

      // @ts-ignore
      console.debug('%c window.jivo_api.setUserToken(%s)', 'color:gold;', window.jivo_jwt)
      // @ts-ignore
      const setUserTokenResult = await window.jivo_api.setUserToken(window.jivo_jwt)

      console.debug('%c setUserTokenResult:', 'color:lime;font-size:18px;', setUserTokenResult)

    } else {
      setTimeout(this.setUserToken, 1000 + (500 * this.attempt))
    }
  }

  mounted() {
    // console.debug('%c views/main/Main.vue mounted', 'color:magenta;font-size:18px;', this.$route)
    this.getAccountData()
  }
}
