
import { Vue, Component } from 'vue-property-decorator';
import I18nDropdown from '@/components/i18nDropdown.vue';
import { dispatchSetBalances, dispatchLogOut, dispatchToggleMobileMenu } from '@/store/main/actions'
import { api } from '@/api'
import MobileMenu from '@/components/Main/MobileMenu.vue'
import { readShowMobileMenu } from '@/store/main/getters'

@Component({
  components: {
    MobileMenu,
    I18nDropdown,
  },
})
export default class Header extends Vue {

  public balance: number = 0
  public showWallet: boolean = false

  public async handleLogOut() {
    await dispatchLogOut(this.$store);
  }

  get showMobileMenu() {
    return readShowMobileMenu(this.$store)
  }

  public toggleMobileMenu() {
    dispatchToggleMobileMenu(this.$store)
  }

  async mounted() {
    try {
      const { data } = await api.getBalances(this.$store.state.main.token)
      if (data) {
        // dispatchIsDev(this.$store, true)
        await dispatchSetBalances(this.$store, data)
        let amount = 0
        for (const key in data) {
          if (data.hasOwnProperty(key) && key.startsWith('dxt')) {
            const val = +data[key]

            if (!isNaN(val)) amount += val
          }
        }
        this.balance = Number(amount.toFixed(8))
      }
    } catch (err) {
      console.debug('%c Header.vue getBalances ERROR:', 'color:red;', err)
      // Разлогиниться, если от бека пришла ошибка
      await dispatchLogOut(this.$store);
    }
  }
}
